import React from "react";

const OccupationalHealth = () => {
    return (  

        <section
        class="py-8 md:py-12 bg-coolGray-50"
      >
        <div class="container px-4 mx-auto">
          <div class="md:max-w-2xl mx-auto mb-12 text-center">
            <div class="flex items-center justify-center">
            
            </div>
            <div class="flex items-center justify-center">
             
            </div>
            
            <div class="inline-block py-1 px-3 font-serif text-xs leading-5 text-yellow-700 font-medium uppercase bg-stone-300 rounded-full shadow-sm">
              Royal Associates
            </div>
          </div>
          <div class="mb-10 block mx-auto max-w-max w-80 overflow-hidden rounded-lg">
            <img src="./Assets/services.png" alt="" />
          </div>
          <div>


          </div>
          <div class="md:max-w-6xl mx-auto">
            
            <div class="mb-6 p-6 border-l-2 border-yellow-500">
              <p class="mb-4 font-serif text-5xl md:text-7xl text-yellow-600 leading-tight font-bold text-coolGray-800 text-left ">
             Occupational Health & Safety
              </p>
             
              <p class="mb-4 font-serif text-xl md:text-xl leading-tight font-light text-coolGray-800 text-justify md:text-left ">
              Royal Associates comes with a decade of experience making workspaces safer through effective case management, and consultancy. 
              Do you have gaps in the overall health and safety of your workspace? Can your employees benefit from understanding their health and safety rights and responsibilities? 
              If this sounds like your business, Royal Associates can consult and provide recommendations to assist your company in remaining within regulatory compliance.</p>
              
              <br></br>
              <span class="text-right font-serif text-base md:text-lg text-coolGray-400 font-bold ">
                Royal Associates
              </span>
              
            </div>
          </div>
        </div>
      </section>
    );
}


export default OccupationalHealth;